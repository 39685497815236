.popup {
  justify-content: center;
  padding: 10pt;
}

.popup-item {
  justify-content: center;
  padding: 10pt;
}

.accordion {
  flex-direction: row-reverse;
}

.detail {
    padding: 0;
    margin: 'none';
}

.event-detail-image {
  width: 100%;
  /* aspect-ratio: 4/3; */
  border-radius: 2%;
}

.event-details-title {
  font-size: 18pt;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  align-content: center;
  text-align: center;
}

.event-detail-description {
  font-size: 12pt;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  align-content: center;
  text-align: center;
}

.button {
  border-radius: "15%";
  font-size: "10pt";
}