ul {
    list-style-type: none;
    padding: 0;
    margin: 'none';
}
.event-list-body {
    width: "100%";
    position: center;
    margin: 15px;
    border-radius: 15%;
}

.event-card-body {
    width: "100%";
    border-radius: 15%;
    margin: 25px;
}