#chat2 .form-control {
  border-color: transparent;
}

#chat2 .form-control:focus {
  border-color: transparent;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.my-custom-scrollbar {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: auto;
}

.xsmall {
  font-size: 9px;
}

.chat-message {
  max-width: 200px;
}

.bg-gainsboro { 
  background-color: #D3D3D3;
}

.chat-conversation-size {
  height: "100%";
}

